import React from 'react';
import '../Styles/requestotpbutton.css';

const RequestOtpButton = ({ Bname, classname, onClick }) => {
  return (
    <button className={classname} onClick={onClick}>
      {Bname}
    </button>
  );
};

export default RequestOtpButton;
