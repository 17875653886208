import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import LogoImage from '../../Asets/DigitalConnexionGroupLogo_Black.png';
import Registra from '../../Asets/registrat.jpg';
import SendOtpButton from '../../Components/SendOtpButton';
import RegisterImage from '../../Asets/registrat.jpg';
import Image from '../../Components/Image';
import { omit } from 'lodash';
import InputField from '../../Components/InputField';
import SaveChangesButton from '../../Components/SaveChangesButton';
import RequestOtpButton from '../../Components/RequestOtpButton';
import { validateEmail, validateMobile } from '../../Components/Validation';
import { ShowSuccessAlert, ShowErrorAlert } from '../../Components/Alert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ShowLoader } from '../../Components/Loader';
import { Video } from '../../Components/Video';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment';
import '../../Styles/register.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth:"250px",
  maxWidth:"500px",
  bgcolor: 'background.paper',
  boxShadow: 30,
  p: 4,
  textAlign:'center'
};

const blacklist_ids = ['323', '324', '326'];

const PERSONALIZED_APP_ID =
  'b6d31b014c44fd0cda2d9c7b72be5a8335e2fbd838bcd23b566ce8812561132a0298aa3c9fb5bea42a5181fabd9d95ca80df02eaf76871f12ebc1b97460acafb';
// const validategenerateForm = (values) => {
//   const error = {};
//   if (!values.email && !values.phone) {
//     error.phone = 'Email OR Phone is required';
//   } else if (values.email && !validateEmail(values.email)) {
//     error.email = 'Enter a valid Email';
//   } else if (values.phone && !validateMobile(values.phone)) {
//     error.phone = 'Enter a valid Phone No';
//   }
//   return error;
// };

const validategenerateForm = (values) => {
  const error = {};
  if (!values.email) {
    error.email = 'Email is required';
  } else if (values.email && !validateEmail(values.email)) {
    error.email = 'Enter a valid Email';
  }
  if (!values.salutation) {
    error.salutation = 'Salutation is required';
  }
  if (!values.firstName) {
    error.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    error.lastName = 'Last Name is required';
  }
  return error;
};

const validateOtpData = (values) => {
  const error = {};
  if (!values.otp) {
    error.otp = 'OTP is required';
  }
  return error;
};

const Register = () => {
  const [nextPage, setNextPage] = useState(false);
  const [taskIdAnswerMap, setTaskIdAnswerMap] = useState({});
  const [otpDataFromApi, setOtpDataFromApi] = useState({});
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    type: 'success'
  });
  const [token, setToken] = useState('');
  const [task, setTask] = useState([]);
  const [otpData, setOtpData] = useState({
    otp: ''
  });
  const [regData, setRegData] = useState({
    email: '',
    name: '',
    otp: '',
    salutation: '',
    firstName: '',
    lastName: '',
    middleName: ''
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [errorMessagesForOtp, setErrorMessagesForOtp] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = React.useState(false);
  const [objForModel, setObjForModel] = React.useState({
    Title: '',
    Message: ''
  });

  const handleIsModelOpen = () => setIsModelOpen(true);
  const handleIsModelClose = () => {
    setIsModelOpen(false);
    setObjForModel({
      Title: ''
    });
  };

  const handleOnSendOtp = () => {
    try {
      const validateObj = validategenerateForm(regData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});

        const body = {
          verification: {
            custom_app_id: PERSONALIZED_APP_ID
          }
        };
        if (regData?.email) {
          body.verification['email'] = regData.email;
        }

        fetch('https://api.sociana.in/api/v3/generate_code', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return (
                setSnackbarData({
                  message: 'OTP Send Successfully.',
                  type: 'success'
                }),
                response.json()
              );
            }
            return (
              setSnackbarData({
                message: 'Something Went Wrong.',
                type: 'error'
              }),
              response.json()
            );
          })
          .then((results) => {
            return setOtpDataFromApi({ ...results?.data?.attributes });
          });
      }
    } catch (error) {
      setSnackbarData({
        message: 'Something Went Wrong.',
        type: 'error'
      });
      console.error(error);
    }
  };

  const NoticeParaOneText =
    '--> Please Note For Domestic Travel, kindly carry any Govt. Issued ID  for flight check-in purposes';
  const NoticeParaTwoText =
    '--> For International Travellers, kindly carry your valid Passport & Visa';

  const NextPageHandler = (token) => {
    setNextPage(true);
    getHospitalityTaskWithAnswers(token);
  };

  const joinOccasion = (token) => {
    try {
      fetch(
        `https://api.sociana.in/api/v2/custom_app/oc/my_occasions?app_id=${PERSONALIZED_APP_ID}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
        .then((res) => {
          if (res.status == 401) {
            return (
              setSnackbarData({
                message: (
                  <p>
                    Access denied. Please reach out to us at{' '}
                    <a href="mailto:events@digitalconnexion.com">
                      events@digitalconnexion.com
                    </a>{' '}
                    for further assistance.
                  </p>
                ),
                type: 'error'
              }),
              res.json()
            );
          } else if (res.status == 200 || res.status == 201) {
            return (
              setSnackbarData({
                message: 'Login Success.',
                type: 'success'
              }),
              res.json()
            );
          }
          return setSnackbarData({
            message: 'Somthing Went Wrong.',
            type: 'error'
          });
        })
        .then((res) => {
          if (res && !res.error && token) {
            NextPageHandler(token);
          }
        });
    } catch (err) {
      setSnackbarData({
        message: 'Somthing Went Wrong.',
        type: 'error'
      });
      console.log(err);
    }
  };

  const handleChange = (e) => {
    try {
      const generateValue = e?.target?.value;
      const inputName = e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setRegData({
        ...regData,
        [inputName]: generateValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getHospitalityTaskWithAnswers = (token) => {
    try {
      fetch(
        `https://api.sociana.in/api/v2/oc/145/visitant/hospitality/get_hospitality_task_with_answer`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const tasksData = result.data.map((item) => {
              let finalData = {};
              const taskType =
                item?.attributes?.taskType === 'request'
                  ? 'currentUserRequestedHospitalityTaskAnswers'
                  : 'currentUserProvidedHospitalityTaskAnswers';
              if (
                item?.relationships?.[taskType]?.data?.length > 0 &&
                result?.included?.length > 0
              ) {
                const id = item?.relationships?.[taskType]?.data[0]?.id;
                const answerData = result?.included?.find(
                  (item) => item.id === id
                );

                finalData = { ...answerData.attributes };
              }

              setTaskIdAnswerMap((el) => ({
                ...el,
                [item.id]: finalData.answer ?? ''
              }));
              return {
                ...item.attributes,
                id: item.id,
                answer: { ...finalData }
              };
            });
            let newData = [];
            for (let i = 0; i < Math.ceil(tasksData.length / 2); i++) {
              if (i === 0) {
                newData[i] = [];
                if (tasksData[0]) {
                  newData[i][0] = tasksData[0];
                }
                if (tasksData[1]) {
                  newData[i][1] = tasksData[1];
                }
              } else {
                newData[i] = [];
                if (tasksData[i * 2]) {
                  newData[i][0] = tasksData[i * 2];
                }
                if (tasksData[i * 2 + 1]) {
                  newData[i][1] = tasksData[i * 2 + 1];
                }
              }
            }
            setTask(newData);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnNextClick = () => {
    try {
      const validateObj = validateOtpData(otpData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessagesForOtp(validateObj);
        return;
      } else {
        setErrorMessagesForOtp({});
        setIsLoading(true);

        const bodyforRegisterUser = {
          login: {
            email_or_mobile: regData.email,
            verification_code: otpData.otp
          }
        };
        const bodyforUnRegisterUser = {
          login: {
            email_or_mobile: regData.email,
            verification_code: otpData.otp
          },
          user: {
            first_name: regData.middleName
              ? `${regData.salutation} ${regData.firstName} ${regData.middleName}`
              : `${regData.salutation} ${regData.firstName}`,
            last_name: regData.lastName
          }
        };

        fetch('https://api.sociana.in/api/v3/sessions', {
          method: 'POST',
          body:
            otpDataFromApi.isRegisteredUser === true
              ? JSON.stringify(bodyforRegisterUser)
              : JSON.stringify(bodyforUnRegisterUser),
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.status == 422) {
              return (
                setSnackbarData({
                  message: 'Please enter correct OTP.',
                  type: 'error'
                }),
                response.json()
              );
            }
            return (
              setSnackbarData({
                message: 'Somthing Went Wrong.',
                type: 'error'
              }),
              response.json()
            );
          })
          .then((results) => {
            if (results?.meta?.token) {
              setToken(results.meta.token);
              joinOccasion(results.meta.token);
            }
          });
      }
    } catch (error) {
      ShowErrorAlert({
        message: 'Somthing Went Wrong',
        position: 'top-right',
        theme: 'dark'
      });
      console.log(error);
    }
  };

  const handleChangeForOtp = (e) => {
    try {
      const otpValue = e?.target?.value;
      const inputName = e?.target?.name;
      if (Object.keys(errorMessagesForOtp).length > 0) {
        setErrorMessagesForOtp({});
      }
      setOtpData({
        ...otpData,
        [inputName]: otpValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (taskIdAnswerMap['322'] === 'No') {
      let newIds = { ...taskIdAnswerMap };
      newIds = omit(newIds, 323);
      newIds = omit(newIds, 324);
      newIds = omit(newIds, 326);
      setTaskIdAnswerMap({
        ...newIds
      });
    }
  }, [task]);

  const handleTaskAnswerChange = (e) => {
    // if (e.target.name === '319') {
    //   setTaskIdAnswerMap((item) => ({ ...item, 319: e.target.value }));
    //   if (!validateMobile(e.target.value)) {
    //     setErrorMessages({ 319: 'Please enter a valid phone number' });
    //     return;
    //   } else {
    //     let prevErrMsg = { ...errorMessages };
    //     prevErrMsg = omit(prevErrMsg, 319);
    //     setErrorMessages({ ...prevErrMsg });
    //   }
    //   return;
    // }
    if (e.target.name === '322') {
      if (e.target.value === 'Yes') {
        setTaskIdAnswerMap((item) => ({
          ...item,
          [e.target.name]: e.target.value,
          323: '',
          324: '',
          326: ''
        }));
      } else {
        let newIds = { ...taskIdAnswerMap };
        newIds = omit(newIds, 323);
        newIds = omit(newIds, 324);
        newIds = omit(newIds, 326);
        newIds[e.target.name] = e.target.value;
        setTaskIdAnswerMap({ ...newIds });
      }
      return;
    }
    setTaskIdAnswerMap((item) => ({
      ...item,
      [e.target.name]: e.target.value
    }));
  };

  const handleSaveTask = () => {
    try {
      const formData = new FormData();

      Object.keys(taskIdAnswerMap).forEach((key) => {
        formData.append(`hospitality_task_answer[][hospitality_task_id]`, key);
        formData.append(
          `hospitality_task_answer[][answer]`,
          taskIdAnswerMap[key]
        );
      });

      fetch(
        'https://api.sociana.in/api/v2/oc/145/visitant/hospitality/update_multiple_hospitality_req_task_answer',
        {
          method: 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              setObjForModel({
                Title: 'Success!'
              }),
              handleIsModelOpen(),
              res.json()
            );
          }
          return (
            setObjForModel({
              Title: 'Error...'
            }),
            handleIsModelOpen(),
            res.json()
          );
        })
        .then((result) => {
          if (result) {
            // setSnackbarData({
            //   message: 'Data updated successfully',
            //   type: 'success'
            // });
          }
        });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSaveRegistraionClose=()=>{
    setObjForModel({Title: 'Registration has been closed.'})
    handleIsModelOpen()
  }

  const handleClose = () => {
    setSnackbarData({
      message: '',
      type: 'success'
    });
  };

  return (
    <>
      <div className="landing_page_container">
        {/* HEADER SEACTION START.... */}
        <div className="header_box">
          <div className="header_logo">
            <img className="logo_image" src={LogoImage} alt="Logo" />
          </div>
          <nav className="navbar_box">
            <ul>
              <li>
                <NavLink
                  to="/"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guideline"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Guidelines
                </NavLink>
              </li>
              <li className="register_list_style">
                <NavLink
                  to="/register"
                  className="register_navlink_style"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Register Now
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="register_container">
          <div className="register_contant">
            <div className="register_contant_left">
              <h2 className="register_contant_heading">Registration</h2>
              {/* <p className="register_contant_para">
                Register
                <br />
                for the event
                <br />
                and be a part of
                <br />a history
              </p> */}
            </div>
            <div className="register_contant_right">
              <Image
                SideImage={RegisterImage}
                classname="image_container_style"
              />
            </div>
          </div>
        </div>
        {nextPage === true ? (
          <div className="login_container">
            <h1 className="login_heading" style={{ color: '#000000' }}>
              Login
            </h1>
            <Grid
              container
              item
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              {task.map((item) => {
                const data1 = item?.[0];
                const data2 = item?.[1];

                const arrayOfTastId = [
                  '313',
                  '314',
                  '329',
                  '319',
                  '325',
                  '328',
                  '315',
                  '316',
                  '317',
                  '318',
                  '322',
                  '326',
                  '323',
                  '324'
                ];

                return (
                  <Grid container item style={{ margin: '20px 0' }}>
                    {data1 &&
                    taskIdAnswerMap[data1?.id] !== undefined &&
                    arrayOfTastId.includes(data1?.id) ? (
                      <Grid flex={1} textAlign="center">
                        {data1.dataInputType === 'mcq' ? (
                          <>
                            <Typography style={{ marginBottom: 5, height: 30 }}>
                              For Local Travellers
                            </Typography>
                            <FormControl>
                              <InputLabel id="demo-simple-select-disabled-label">
                                Drop/Pickup Needed?
                              </InputLabel>
                              <Select
                                name={data1?.id}
                                label="Drop/Pickup Needed?"
                                style={{
                                  minWidth: 300,
                                  background: 'white'
                                }}
                                defaultValue={''}
                                value={taskIdAnswerMap[data1.id] ?? ''}
                                onChange={handleTaskAnswerChange}>
                                <MenuItem disabled value="">
                                  Pickup/Drop Needed?
                                </MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        ) : data1?.dataInputType === 'datetime' ? (
                          <>
                            <Typography style={{ marginBottom: 5, height: 30 }}>
                              {data1?.name}
                            </Typography>
                            <DatePicker
                              size="lg"
                              placement="top"
                              format="dd/MM/yyyy HH:mm"
                              name={data1?.id}
                              // className={styles.datePicker}
                              defaultValue={''}
                              cleanable={false}
                              value={
                                taskIdAnswerMap[data1.id]
                                  ? new Date(
                                      moment(
                                        taskIdAnswerMap[data1.id],
                                        'DD-MM-YYYY HH:mm'
                                      )
                                    )
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleTaskAnswerChange({
                                  target: { name: data1?.id, value: date }
                                })
                              }
                              style={{ minWidth: 300 }}
                              placeholder={data1?.name}
                            />
                            <Typography
                              style={{ marginTop: 5, fontSize: '13px' }}>
                              {data1.id == '315'
                                ? 'Kindly choose correct arrival date & time.'
                                : data1.id == '317'
                                ? 'Kindly choose correct departure date & time.'
                                : ''}
                            </Typography>
                          </>
                        ) : (
                          <>
                            {' '}
                            <Typography style={{ marginBottom: 5, height: 30 }}>
                              {data1?.name}
                            </Typography>
                            <TextField
                              placeholder={data1?.name}
                              disabled={data1?.taskType === 'provide'}
                              onChange={handleTaskAnswerChange}
                              value={taskIdAnswerMap[data1.id]}
                              style={{
                                minWidth: 300,
                                background: 'white'
                              }}
                              name={data1?.id}
                            />
                          </>
                        )}
                        {errorMessages[data1?.id] ? (
                          <Typography>{errorMessages[data1?.id]}</Typography>
                        ) : null}
                      </Grid>
                    ) : null}
                    {data2 &&
                    taskIdAnswerMap[data2?.id] !== undefined &&
                    arrayOfTastId.includes(data2?.id) ? (
                      <Grid flex={1} textAlign="center">
                        {data2.dataInputType === 'mcq' ? (
                          <>
                            <Typography style={{ marginBottom: 5 }}>
                              For Local Travellers
                            </Typography>
                            <FormControl>
                              <InputLabel id="demo-simple-select-disabled-label">
                                Drop/Pickup Needed?
                              </InputLabel>
                              <Select
                                name={data2?.id}
                                label="Drop/Pickup Needed?"
                                style={{ minWidth: 300, background: 'white' }}
                                onChange={handleTaskAnswerChange}>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        ) : data2?.dataInputType === 'datetime' ? (
                          <>
                            <Typography style={{ marginBottom: 5, height: 30 }}>
                              {data2?.name}
                            </Typography>
                            <DatePicker
                              size="lg"
                              placement="top"
                              format="dd/MM/yyyy HH:mm"
                              name={data2?.id}
                              // className={styles.datePicker}
                              defaultValue={''}
                              cleanable={false}
                              value={
                                taskIdAnswerMap[data2.id]
                                  ? new Date(taskIdAnswerMap[data2.id])
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleTaskAnswerChange({
                                  target: { name: data2?.id, value: date }
                                })
                              }
                              style={{ minWidth: 300 }}
                              placeholder={data2?.name}
                            />
                            <Typography
                              style={{ marginTop: 5, fontSize: '13px' }}>
                              {data2.id == '315'
                                ? 'Kindly choose correct arrival date & time.'
                                : data1.id == '317'
                                ? 'Kindly choose correct departure date & time.'
                                : ''}
                            </Typography>
                          </>
                        ) : (
                          <>
                            {' '}
                            <Typography style={{ marginBottom: 5, height: 30 }}>
                              {data2?.name}
                            </Typography>
                            <TextField
                              placeholder={data2?.name}
                              onChange={handleTaskAnswerChange}
                              value={taskIdAnswerMap[data2.id]}
                              disabled={data2?.taskType === 'provide'}
                              style={{
                                width: 300,
                                background: 'white'
                              }}
                              name={data2?.id}
                            />
                            <Typography></Typography>
                          </>
                        )}
                        {errorMessages[data2?.id] ? (
                          <Typography
                            variant="body2"
                            style={{ color: 'red', marginTop: 7 }}>
                            {errorMessages[data2?.id]}
                          </Typography>
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>
                );
              })}
            </Grid>

            <div className="save_btn">
              <SaveChangesButton
                Bname="SAVE CHANGES"
                onClick={handleSaveRegistraionClose}
                disabled={Object.keys(errorMessages).length > 0}
                classname="save_btn_slide save_slide_left"
              />
            </div>
          </div>
        ) : (
          <div className="login_container">
            <h2 style={{ color: 'red', marginTop: '0' }}>
              Registration has been closed.
            </h2>
            <h2 className="login_heading" style={{ color: '#000000' }}>
              Login
            </h2>
            {/* <div className="input_container">
              <InputField
                type="name"
                placeholder="Name*"
                classname="inputfield"
                name="name"
                value={regData.name}
                onChange={handleChange}
              />
              <InputField
                type="email"
                name="email"
                value={regData.email}
                onChange={handleChange}
                placeholder="Official Email*"
                classname="inputfield"
              />
            </div> */}
            <Grid
              style={{
                margin: '20px 0',
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Grid
                container
                item
                wrap="wrap"
                style={{
                  // margin: '20px 10px',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Grid
                  flex={1}
                  textAlign="center"
                  style={{ margin: '10px 0 10px 30px' }}>
                  <TextField
                    placeholder="Salutation*"
                    onChange={handleChange}
                    value={regData.salutation}
                    style={{ width: 250, background: 'white' }}
                    name="salutation"
                  />
                  <Typography
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '5px',
                      paddingLeft: '5px'
                    }}>
                    {errorMessages.salutation}
                  </Typography>
                </Grid>
                <Grid
                  flex={1}
                  textAlign="center"
                  style={{ margin: '10px 0 10px 30px' }}>
                  <TextField
                    placeholder="First Name*"
                    onChange={handleChange}
                    value={regData.firstName}
                    style={{ width: 250, background: 'white' }}
                    name="firstName"
                  />
                  <Typography
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '5px',
                      paddingLeft: '5px'
                    }}>
                    {errorMessages.firstName}
                  </Typography>
                </Grid>
                <Grid
                  flex={1}
                  textAlign="center"
                  style={{ margin: '10px 0 10px 30px' }}>
                  <TextField
                    placeholder="Middle Name"
                    onChange={handleChange}
                    value={regData.middleName}
                    style={{ width: 250, background: 'white' }}
                    name="middleName"
                  />
                </Grid>
                <Grid
                  flex={1}
                  textAlign="center"
                  style={{ margin: '10px 0 10px 30px' }}>
                  <TextField
                    placeholder="Last Name*"
                    onChange={handleChange}
                    value={regData.lastName}
                    style={{ width: 250, background: 'white' }}
                    name="lastName"
                  />
                  <Typography
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '5px',
                      paddingLeft: '5px'
                    }}>
                    {errorMessages.lastName}
                  </Typography>
                </Grid>
                <Grid
                  flex={1}
                  textAlign="center"
                  style={{ margin: '10px 0 10px 30px' }}>
                  <TextField
                    placeholder="Official Email*"
                    onChange={handleChange}
                    value={regData.email}
                    style={{ width: 250, background: 'white' }}
                    name="email"
                  />
                  <Typography
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '5px',
                      paddingLeft: '5px'
                    }}>
                    {errorMessages.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {typeof otpDataFromApi.isRegisteredUser === 'boolean' ? null : (
              <RequestOtpButton
                Bname="REQUEST OTP"
                classname="request_next_btn_slide request_nexr_slide_left"
                onClick={handleOnSendOtp}
              />
            )}

            <Typography variant="body2" style={{ marginTop: 15 }}>
              Note: Please check your spam folder for the OTP if you have not
              received it in your inbox.
            </Typography>
            <Grid container item wrap="nowrap" style={{ margin: '20px 10px' }}>
              {typeof otpDataFromApi.isRegisteredUser === 'boolean' ? (
                <Grid flex={1}>
                  <TextField
                    name="otp"
                    onChange={handleChangeForOtp}
                    value={otpData.otp}
                    placeholder="Enter OTP*"
                    style={{
                      width: '45%',
                      background: 'white'
                    }}
                  />
                  <Typography
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '5px',
                      paddingLeft: '5px'
                    }}>
                    {errorMessagesForOtp.otp}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: '20px'
                    }}>
                    For OTP, please check your official email
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <SaveChangesButton
              Bname="NEXT"
              classname="next_btn_slide nexr_slide_left"
              onClick={handleOnNextClick}
            />
            <div className="did_not_recieved_otp" onClick={handleOnSendOtp}>
              <button className="re_send_otp">Resend the OTP</button>
            </div>
            <p className="request_again_otp_para">
              Request again in <spna>30</spna> seconds.
            </p>
          </div>
        )}
      </div>
      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        style={{ border: '2px solid black', borderRadius: 5 }}
        message={snackbarData.message}>
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar>

      {isModelOpen && (
        <Modal
          open={isModelOpen}
          onClose={handleIsModelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontWeight:'700',color:'red'}}>
              {objForModel.Title}
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {objForModel.Title === 'Success!' ? (
                'Thank you for sharing your details. We look forward to seeing you in Chennai, India.'
              ) : objForModel.Title === 'Error...' ? (
                <div>
                  <p>
                    In case of query, write to us at{' '}
                    <a href="mailto:events@digitalconnexion.com">
                      events@digitalconnexion.com
                    </a>
                  </p>
                </div>
              ) : (
                ''
              )}
            </Typography> */}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Register;
