import React from 'react';
import '../Styles/savechangesbutton.css';

const SaveChangesButton = ({ Bname, classname, onClick, ...restProps }) => {
  return (
    <button {...restProps} className={classname} onClick={onClick}>
      {Bname}
    </button>
  );
};

export default SaveChangesButton;
