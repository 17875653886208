import React from 'react'

const Image = ({
    SideImage, classname }) => {
    return (
        <div className={classname}>
            <img className='slideImage' src={SideImage} alt='Contant' />
        </div>
    )
}

export default Image;