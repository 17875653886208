import React, { useState } from 'react';
import '../Styles/inputfield.css';

const InputField = ({
  type,
  name = '',
  placeholder,
  classname,
  value = '',
  onChange,
  errorMessage
}) => {
  return (
    <>
      <div className="input_dynamic_box">
        <input
          className={classname}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
      <p className="input_alert-msg">{errorMessage}</p>
    </>
  );
};

export default InputField;
