import React from 'react';
import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import LandingPage from './Pages/Home';
import Guideline from './Pages/Guideline';
import Register from './Pages/Register';
// import Footer from './Components/Footer';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="guideline" element={<Guideline />} />
          <Route path="register" element={<Register />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
