import React from 'react';
import { useState, useEffect } from "react";
import Dots from "../Asets/TimerDots.png";

const Deadline = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = "January, 11, 2024";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='timer'>
            <div className='timer_contant'>
                <p className='timer_contant_days'>{days < 0 ? "00" : days < 10 ? "0" + days : days}</p>
                {/* <p className='timer_contant_days'>00</p> */}
                <p className='timer_contant_column_two'>DAYS</p>
            </div>
            <div className='timer_dots'>
                <img src={Dots} alt='::' />
            </div>
            <div className='timer_contant'>
                <p className='timer_contant_hours'>{hours < 0 ? "00" : hours < 10 ? "0" + hours : hours}</p>
                {/* <p className='timer_contant_hours'>00</p> */}
                <p className='timer_contant_column_two'>HOURS</p>
            </div>
            <div className='timer_dots'>
                <img src={Dots} alt='::' />
            </div>
            <div className='timer_contant'>
                <p className='timer_contant_minutes'>{minutes < 0 ? "00" : minutes < 10 ? "0" + minutes : minutes}</p>
                {/* <p className='timer_contant_minutes'>00</p> */}
                <p className='timer_contant_column_two'>MINUTES</p>
            </div>
            <div className='timer_dots'>
                <img src={Dots} alt='::' />
            </div>
            <div className='timer_contant'>
                <p className='timer_contant_seconds'>{seconds < 0 ? "00" : seconds < 10 ? "0" + seconds : seconds}</p>
                {/* <p className='timer_contant_seconds'>00</p> */}
                <p className='timer_contant_column_two'>SECONDS</p>
            </div>
        </div>
    )
}

export default Deadline;