import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ShowSuccessAlert = ({ message, position, theme }) => {
  toast.success(`${message}`, {
    position: `${position}`,
    theme: `${theme}`
  });
};

export const ShowErrorAlert = ({ message, position, theme }) => {
  toast.error(`${message}`, {
    position: `${position}`,
    theme: `${theme}`
  });
};
