import React from 'react';
import '../../Styles/landingpage.css';
import { NavLink } from 'react-router-dom';
import Button from '../../Components/Button';
import Image from '../../Components/Image';
import LogoImage from '../../Asets/DigitalConnexionGroupLogo_Black.png';
import LocationIcon from '../../Asets/LocationIcon.png';
import CalenderIcon from '../../Asets/CalenderIcon.png';
import SideImage from '../../Asets/BannerImg.png';
import { Video } from '../../Components/Video';
import Deadline from '../../Components/Deadline';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="landing_page_container">
        {/* HEADER SEACTION START.... */}
        <div className="header_box">
          <div className="header_logo">
            <img className="logo_image" src={LogoImage} alt="Logo" />
          </div>
          <nav className="navbar_box">
            <ul>
              <li>
                <NavLink
                  to="/"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guideline"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Guidelines
                </NavLink>
              </li>
              <li className="register_list_style">
                <NavLink
                  to="/register"
                  className="register_navlink_style"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Register Now
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        {/* HEADER SEACTION END.... */}
        {/* BANNER SECTION START */}
        <div className="banner_container">
          <div className="banner_contant">
            <div className="banner_contant_left">
              <p className="banner_contant_para" style={{ lineHeight: '35px' }}>
                Unveiling a
              </p>
              <p className="banner_contant_para">new era of</p>
              <span
                className="banner_contant_header"
                style={{ lineHeight: '25px' }}>
                data centers.
              </span>
              <p className="banner_contant_place_date">
                <span>
                  <img className="landing_Icon" src={LocationIcon} alt="Icon" />{' '}
                  Chennai, India
                </span>
                <span>
                  <img className="landing_Icon" src={CalenderIcon} alt="Icon" />{' '}
                  11<sup>th</sup> & 12<sup>th</sup> January, 2024
                </span>
              </p>
              <Button
                navigatePath="/register"
                className="home_reg_btn_slide home_reg__slide_left"
                BtnName="Register Now"
              />
            </div>
            <div className="banner_contant_right">
              <Image SideImage={SideImage} classname="image_container_style" />
            </div>
          </div>
        </div>
        {/* BANNER SECTION END */}
        {/* LANDING PAGE DEADLINE TIMER START */}
        <div className="deadline_timer_box">
          <Deadline />
        </div>
        <div className="DataCenter_container" style={{ color: '#000000' }}>
          <h2 className="DataCenter_healding">
            Launch of our first{' '}
            <span className="DataCenter_text">Data Center</span>
            <br /> at Chennai, India
          </h2>
          <div className="datacenter_para_box">
            <p className="DataCenter_para">
              Join us to celebrate the launch of our first data center in India,
              as we transform the industry with our cutting-edge technology,
              global platform, and local presence. We plan to bring together a
              vibrant community of enterprises and connectivity providers that
              will help you shape your digital future.
            </p>
            <p className="DataCenter_para">
              Build new connections at the engaging pre-launch evening, followed
              by an opening ceremony the next day.
            </p>
          </div>
          <Video classname="home_video" />
        </div>
        <div className="schedule_event_container">
          <h2 className="schedule_healding">SCHEDULE</h2>
          <div className="schedule_days_container">
            <div className="schedule_days_left">
              <h1
                className="schedule_days_title"
                style={{ lineHeight: '25px', color: '#000000' }}>
                Day 1: 11<sup>th</sup> January, 2024
                <br />
                <span>Welcome Dinner</span>
              </h1>
              <span style={{fontWeight:'600',color:"black"}}>Time: 18:00</span>
              <br />
              <span style={{fontWeight:'600',color:"black"}}>Location: The Leela Chennai</span>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                13:00 – 18:00
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">Arrival & check-in</p>
              </div>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                18:00 – 19:30
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">
                Welcome Note & data center preview
                </p>
              </div>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                19:30 – 22:30
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">Entertainment & dinner</p>
              </div>
            </div>
            <div className="schedule_days_right">
              <h1
                className="schedule_days_title"
                style={{ lineHeight: '25px', color: '#000000' }}>
                Day 2: 12<sup>th</sup> January, 2024
                <br />
                <span>Data Center Launch</span>
              </h1>
              <span style={{fontWeight:'600',color:"black"}}>Time:  10:30</span>
                <br />
              <span style={{fontWeight:'600',color:"black"}}>Location: MAA10, Digital Connexion, Ambattur</span>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                08:00 – 09:00
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">Breakfast</p>
              </div>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                  10:15 - 11:20
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">
                  Arrival at the data center & keynote
                </p>
              </div>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                  11:30 - 13:00
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">Launch & data center tour</p>
              </div>
              <div
                className="schedule_days_contant"
                style={{ color: '#000000' }}>
                <h2 className="schedule_time" style={{ lineHeight: '20px' }}>
                13:00 - 14:00 
                </h2>
                <div className="schedule_underline"></div>
                <p className="schedule_time_para">Lunch</p>
              </div>
            </div>
          </div>
        </div>
        <div className="register_event_container">
          <div
            class="button_slide slide_down"
            onClick={() => navigate('/register')}>
            REGISTER NOW
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
