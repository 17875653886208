import VideoHome from "../Asets/Video/home.mp4";
import Home from "../Asets/Video/video.mp4";
import VideoSche from "../Asets/Video/schedule.mp4";
import VideoFaqs from "../Asets/Video/faqs.mp4";
import VideoReg from "../Asets/Video/register.mp4";

export const Video = ({ classname }) => {
    return (
        <video className={classname} autoPlay loop muted>
            <source src={Home} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    );
};
export const Video1 = ({ classname }) => {
    return (
        <video className={classname} autoPlay loop muted>
            <source src={VideoHome} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    );
};

// export default Video;

export const Video2 = ({ classname }) => {
    return (
        <video className={classname} autoPlay loop muted>
            <source src={VideoSche} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    );
};

// export default Video1;
export const Video3 = ({ classname }) => {
    return (
        <video className={classname} autoPlay loop muted>
            <source src={VideoFaqs} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    );
};

// export default Video2;
export const Video4 = ({ classname }) => {
    return (
        <video className={classname} autoPlay loop muted>
            <source src={VideoReg} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    );
};
// export default Video3;