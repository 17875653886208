// import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import '../../Styles/guideline.css';
import { NavLink } from 'react-router-dom';
import LogoImage from '../../Asets/DigitalConnexionGroupLogo_Black.png';
import Image from '../../Components/Image';
import GuidelineImage from '../../Asets/GuildlineImage.png';
import Faqs from '../../Asets/faqs.jpeg';
import { Video4 } from '../../Components/Video';
import { Video3 } from '../../Components/Video';
import { Video2 } from '../../Components/Video';
import { Video1 } from '../../Components/Video';
import { useNavigate } from 'react-router-dom';

const Guideline = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="landing_page_container">
        {/* HEADER SEACTION START.... */}
        <div className="header_box">
          <div className="header_logo">
            <img className="logo_image" src={LogoImage} alt="Logo" />
          </div>
          <nav className="navbar_box">
            <ul>
              <li>
                <NavLink
                  to="/"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guideline"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Guidelines
                </NavLink>
              </li>
              <li className="register_list_style">
                <NavLink
                  to="/register"
                  className="register_navlink_style"
                  style={({ isActive }) => ({
                    color: isActive ? '#7700EC' : 'black'
                  })}>
                  Register Now
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="guideline_container">
          <div className="guideline_contant">
            <div className="guideline_contant_left">
              <h2 className="guideline_contant_heading">Guidelines</h2>
              {/* <p className='guideline_contant_para'>Look at the<br />guidelines<br />for the event</p> */}
            </div>
            <div className="guideline_contant_right">
              <div className="image_container_style">
                {/* <img className='gif' src={Gif} alt='Contant' /> */}
                <img
                  className="slideImage"
                  src={GuidelineImage}
                  alt="Contant"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="guideline_details_container"
          style={{ color: '#000000' }}>
          <div className="guideline_details_contant">
            <div className="guideline_details_contant_left">
              <h2
                className="guideline_details_contant_heading"
                style={{ color: '#000000' }}>
                {' '}
                REGISTRATION
              </h2>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>Registration is mandatory for participation.</span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  To make the experience seamless, please provide all relevant
                  details accurately.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Please use the Email ID the invite was sent to. The system
                  will not accept any alternate email IDs.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  This invite is exclusively for you and cannot be transferred.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '23px'
                  }}>
                  &#8718;
                </span>
                <span>
                  <b>
                    {/* Last date for registration is 31<sup>st</sup> December,
                    2023; 1800 Hrs. */}
                    Registration has been closed.
                  </b>
                </span>
              </p>
            </div>

            <div className="guideline_details_contant_right">
              {/* <Image SideImage={regist} classname="event_contant_videoe" /> */}
              <Video1 classname="event_contant_video" />
            </div>
          </div>
          <div className="guideline_details_contant_reverse">
            <div className="guideline_details_contant_left">
              <h2
                className="guideline_details_contant_heading"
                style={{ color: '#000000' }}>
                {' '}
                EVENT ENGAGEMENT
              </h2>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>Refer to the agenda section for details.</span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>Agenda is subject to change.</span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Attending program on both days is highly recommended.
                </span>
              </p>
            </div>

            <div className="guideline_details_contant_right">
              <Video4 classname="event_contant_video" />
            </div>
          </div>
          <div className="guideline_details_contant">
            <div className="guideline_details_contant_left">
              <h2
                className="guideline_details_contant_heading"
                style={{ color: '#000000' }}>
                {' '}
                TRAVEL
              </h2>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Kindly contact your travel desk for visa related queries. For
                  more information visit{' '}
                  <a
                    href="https://indianvisaonline.gov.in/evisa/tvoa.html"
                    target="_blank"
                    style={{ textDecoration: 'none' }}>
                    https://indianvisaonline.gov.in/evisa/tvoa.html
                  </a>
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Local transport including airport pick-ups/drop-offs have been
                  arranged for all guests. Kindly ensure you share accurate
                  details while registering.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Please carry all relevant documents like ID proof, passport,
                  etc.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  For guests travelling to Chennai, stay is recommended at The
                  Leela Palace, Chennai.
                  <br /> <strong>Hotel Address:</strong> The Leela Palace, Adyar
                  Sea Face, Leela Palace Rd, MRC Nagar, Raja Annamalai Puram,
                  Chennai, Tamil Nadu.
                  <br />
                  <strong>Hotel Contact:</strong> +91 89398 98399.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '23px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Note: Room reservation is subject to availability. Kindly make
                  the reservations as soon as possible to avoid any hassle.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '23px'
                  }}>
                  &#8718;
                </span>
                <span>
                  Please write to us for special rates at{' '}
                  <a href="mailto:events@digitalconnexion.com">
                    events@digitalconnexion.com
                  </a>{' '}
                  and we should be able to share a unique link with you.
                </span>
              </p>
            </div>

            <div className="guideline_details_contant_right">
              <Video3 classname="event_contant_video" />
            </div>
          </div>

          <div className="guideline_details_contant_reverse">
            <div className="guideline_details_contant_left">
              <h2
                className="guideline_details_contant_heading"
                style={{ color: '#000000' }}>
                ATTIRE & WEATHER
              </h2>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  <b>Dress code:</b>
                  <br />
                  Pre-Launch Evening – Evening formals.
                  {/* Evening formals */}
                  <br />
                  Campus Launch – Business formals.
                </span>
              </p>
              <p className="guideline_details_contant_para">
                <span
                  style={{
                    fontSize: '23px',
                    color: '#7700ea',
                    marginRight: '5px',
                    lineHeight: '17px'
                  }}>
                  &#8718;
                </span>
                <span>
                  <b>Weather:</b>
                  <br />
                  January is the best time to visit Chennai, India. Daytime
                  temperatures usually range between 25°C - 29°C with moderate
                  heat and humidity.
                </span>
              </p>
            </div>

            <div className="guideline_details_contant_right">
              <Video2 classname="event_contant_video" />
            </div>
          </div>
        </div>

        <div className="faqs_container">
          <div className="faqs_contant">
            <div className="faqs_contant_left">
              <h2 className="faqs_contant_heading">FAQs</h2>
              {/* <p className='faqs_contant_para'>We have taken<br />liberty to go ahead<br />and answer a few<br />for you</p> */}
            </div>
            <div className="faqs_contant_right">
              <Image SideImage={Faqs} classname="image_container_style" />
            </div>
          </div>
        </div>

        <div className="guideline_days_container" style={{ color: '#000000' }}>
          <div className="guidline_rules_left">
            {/* <h1 className='guidline_rules_title'>Registration</h1> */}
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                I am unable to register. Who can I contact for help with
                registration for the event?
              </h2>
              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                In case you are facing problems in registration, please reach
                out to{' '}
                <a href="mailto:events@digitalconnexion.com">
                  events@digitalconnexion.com
                </a>
              </p>
            </div>
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                Do I need to book travel before registering?
              </h2>

              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                Yes, it is recommended. You need to include all travel details
                in the registration form, including flight and arrival/departure
                dates for a seamless experience.
              </p>
            </div>
          </div>
          <div className="guidline_rules_bottom">
            {/* <h1 className='guidline_rules_title'>Transportation</h1> */}
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                I am unable to make it to the data center launch event. What
                should I do?
              </h2>
              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                Kindly inform us at{' '}
                <a href="mailto:events@digitalconnexion.com">
                  events@digitalconnexion.com
                </a>{' '}
                for any change in plans.
              </p>
            </div>
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                Will cabs be provided for intra-city transfers?
              </h2>
              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                Yes.This will be arranged according to details shared while
                registering.
              </p>
              <br />
            </div>
          </div>
          <div className="guidline_rules_right">
            {/* <h1 className='guidline_rules_title'>Accomodation</h1> */}
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                I need to modify the travel plans due to an unexpected
                emergency. What should I do?
              </h2>
              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                You can login to your account using registered Email ID. and
                modify necessary details.
              </p>
            </div>
            <div className="guidline_rules_contant">
              <h2 className="guidline_question" style={{ lineHeight: '20px' }}>
                How should I travel from the airport to the hotel?
              </h2>

              <div className="guidline_underline"></div>
              <p className="guidline_answer_para">
                Local transport including airport pick-ups/drop-offs have been
                arranged for all guests. Kindly ensure you share accurate
                details with us while registering.
              </p>
            </div>
          </div>
        </div>
        <div className="register_event_container" style={{ color: '#000000' }}>
          {/* <h2 className='register_healding'>Register Now</h2> */}
          {/* <p className='DataCenter_para'>Please grace us with your presence and witness history being made with us.<br />Looking forward to hosting you.</p> */}
          <div
            class="button_slide slide_down"
            onClick={() => navigate('/register')}>
            REGISTER NOW
          </div>
          <p className="contact_details">
            <b>Contact us:</b>
          </p>
          <p className="contact_details">
            <b>In case of any query, please feel free to reach out to us!</b>
          </p>
          <p className="contact_details">
            <b>
              Email:{' '}
              <a href="mailto:events@digitalconnexion.com">
                events@digitalconnexion.com
              </a>
            </b>
          </p>
          <p className="contact_details">
            <b>Mobile Number: +91-8208218346</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Guideline;
