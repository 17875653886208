import React from 'react';
import { useNavigate } from "react-router-dom";

const Button = ({ className, BtnName, navigatePath }) => {

    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(navigatePath)} className={className}>{BtnName}</button>
    )
}

export default Button;